import React, { Component } from 'react'
import './Intro.css'

class Intro extends Component {

    constructor(props){

        super(props)

        this.state = {}

        this.video = React.createRef()

    }

    play(){

        this.video.current.playbackRate = 1
        this.video.current.play()

    }

    componentDidMount(){

        this.video.current.onended = () => {

            console.log('video ended')

            this.props.onIntroVideoEnded()

        }

    }

    render() {
        const isMobile = window.innerWidth < 1024;
        
        return (
            <div className={"intro-component " + this.props.className}>
                {
                    isMobile ?
                    <video ref={this.video} muted playsInline src={require('../video/intro-mobile.mp4')}></video>
                    :
                    <video ref={this.video} muted playsInline src={require('../video/intro.mp4')}></video>
                }
            </div>
        )
    }

}

export default Intro
