import React, { Component } from 'react'
import './App.css'
import Intro from './components/Intro'
import Logo from './components/Logo'
import Menu from './components/Menu'
import Footer from './components/Footer'
import Loading from './components/Loading'
import { withCookies } from 'react-cookie'

let shouldRenderIntro = true

class App extends Component {

    constructor(props){

        super(props)

        this.state = {
            intro: true,
            loading: false,
            tooltips: false,
        }

        this.intro = React.createRef()
        this.logo = React.createRef()

    }

    componentWillMount(){

        const { cookies } = this.props

        const intro = cookies.get('greenpeace-intro')

        shouldRenderIntro = intro !== '1'

        console.log(intro, shouldRenderIntro)

    }

    componentDidMount(){

        this.logo.current.config()
        this.logo.current.run()

        if(shouldRenderIntro){

            this.intro.current.play()

        }

    }

    onIntroVideoEnded = () => {

        const { cookies } = this.props

        this.onLogoIntroFinished()

        cookies.set('greenpeace-intro', '1', { path: '/' })

    }

    onLogoLoaded = () => {

        console.log('loaded')

        if(!shouldRenderIntro){

            this.onLogoIntroFinished()

        }

    }

    runNextIntroText = (text) => {

        //this.intro.current.next()

    }

    onLoading = (isLoading) => {
        if(isLoading) {
            this.setState({
                loading: true
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    onLogoIntroFinished = async () => {

        console.log('logo intro finished')

        //this.intro.current.next()

        this.setState({
            intro: false,
        })

        this.showTooltip()

        await this.logo.current.draw(true, true)

        //this.logo.current.renderNews(50, true)

    }

    onLogoHistoricalRender = () => {

        console.log('logo rendered')

        this.logo.current.renderNews(50, true)

    }

    onMenuClick = (item, subitem) => {

        this.logo.current.setCategory(item, subitem)

    }

    showTooltip = () => {

        this.setState({
            tooltips: true
        }, () => {

            setTimeout(() => {

                this.setState({
                    tooltips: false
                })

            }, 7000)

        })

    }

    hideTooltip = () => {

        this.setState({
            tooltips: false
        })

    }

    onHelpClick = (e) => {

        e.preventDefault()

        console.log('on help click')

        this.showTooltip()

    }

    render() {
        return (
            <div className={"app" + (this.state.intro ? " intro" : "")}>

                <Loading className={this.state.loading ? 'active' : ''}/>

                {
                    shouldRenderIntro ? 
                    <Intro
                        className={this.state.intro ? "active": ""}
                        ref={this.intro}
                        onIntroVideoEnded={this.onIntroVideoEnded}/> : null
                }

                <main>

                    <button className="help" onClick={this.onHelpClick}>
                        <span className="text">AJUDA</span>
                        <span className="icon">?</span>
                    </button>

                    <Menu
                        onMenuClick={this.onMenuClick}
                        tooltips={this.state.tooltips}/>

                    <Logo
                        ref={this.logo}
                        className={this.state.intro ? "": "active"}
                        onLogoLoaded={this.onLogoLoaded}
                        onLogoHistoricalRender={this.onLogoHistoricalRender}
                        onLoading={this.onLoading}
                        runNextIntroText={this.runNextIntroText}
                        tooltips={this.state.tooltips}
                        hideTooltip={this.hideTooltip}/>

                </main>

                <Footer
                    className={this.state.intro ? '' : 'active'}/>

            </div>
        )
    }

}

export default withCookies(App)
