import React, { Component } from 'react'
import './Loading.css'

class Loading extends Component {

    constructor(props){

        super(props)

        this.state = {}

    }

    render() {

        return (
            <div className={"loading " + this.props.className + " " + this.props.classMenu }>
                <div className="loading-anim">
                    <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        )
    }

}

export default Loading
