import React, { Component } from 'react'
import './Menu.css'

class Menu extends Component {

    constructor(props){

        super(props)

        this.state = {
            category: "florestas",
            subcategory: null,
            menu: [
                {
                    title: "Florestas",
                    value: "florestas",
                    submenu: [
                        {
                            title: "Desmatamento",
                            value: "desmatamento",
                        },
                        {
                            title: "Agropecuária",
                            value: "pecuaria",
                        },
                        {
                            title: "Povos indígenas",
                            value: "indigenas",
                        },
                        {
                            title: "Abaixo-assinado",
                            value: "florestas",
                            url: "https://www.greenpeace.org.br/sem-floresta-sem-vida?utm_source=referral&utm_medium=external&utm_campaign=institucional&utm_content=survivorLogo",
                        },
                    ],
                },
                {
                    title: "Clima",
                    value: "clima",
                    submenu: [
                        {
                            title: "Aquecimento global",
                            value: "paris",
                        },
                        {
                            title: "Uso de energia",
                            value: "energia",
                        },
                    ],
                },
                {
                    title: "Biodiversidade",
                    value: "biodiversidade",
                    submenu: [
                        {
                            title: "Espécies ameaçadas",
                            value: "corais",
                        },
                        {
                            title: "Proteção dos oceanos",
                            value: "oceanos",
                        },
                        {
                            title: "Agrotóxicos",
                            value: "agrotoxicos",
                        },
                    ],
                },
            ],
        }

    }

    onMenuClick = (e, item) => {
        e.preventDefault()

        this.setState({
            category: item,
            subcategory: null,
        })
        
        this.props.onMenuClick(item, null)
    }

    onSubmenuClick = (e, item, subitem) => {

        e.preventDefault()

        this.setState({
            category: item,
            subcategory: subitem,
        })

        this.props.onMenuClick(item, subitem)

    }

    render() {

        let menu = this.state.menu.map(item => {

            const submenu = item.submenu.map(subitem => {

                return (
                    <li className={subitem.value + (this.state.subcategory == subitem.value ? " active" : "")}>
                        { subitem.url ?
                            <a href={subitem.url} target="_blank">{subitem.title}</a>:
                            <button onClick={(e) => { this.onSubmenuClick(e, item.value, subitem.value) }}>{subitem.title}</button>
                        }
                    </li>
                )

            })

            return (
                <li className={item.value + (this.state.category == item.value ? " active" : "")}>
                    <button onClick={(e) => { this.onMenuClick(e, item.value); }}>{item.title}</button>
                    <ul>{submenu}</ul>
                </li>
            )

        })

        return (
            <div className="menu">

                <div className="menu-logo"></div>

                <p>
                    descubra <br/>
                    o que está <br/>
                    acontecendo <br/>
                    com a natureza_
                </p>

                <div className="menu-container">
                    <ul className="menu-list">{menu}</ul>
                    <div className={"menu-tooltip" + (this.props.tooltips ? " active" : "")}>
                        <div className="bar"></div>
                        <div className="text">
                            <span>SELECIONE UM TEMA</span><br/>
                            <span>PARA VISUALIZAR</span><br/>
                            <span>O QUE ESTÁ</span><br/>
                            <span>ACONTECENDO.</span>
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default Menu
