import React, { Component } from 'react'
import { Marquee, loop } from 'dynamic-marquee'
import './Footer.css'

class Footer extends Component {

    constructor(props){

		super(props)
		
		this.timelineImage = React.createRef()
		this.timelineContainer = React.createRef()

        this.state = {
			timelineDifference: 0,
			timelineScroll: 0,
			timelinePosition: 0,
        	timer: '',
        	phraseIndex: -1,
        	phrases: [
        		'Hoje 500 pessoas devem morrer vítimas de agrotóxicos no mundo.',
				'Só hoje 3 milhões das árvores devem ser desmatadas na Amazônia.',
				'Hoje outras 72 espécies devem ser extintas no planeta.',
				'Falta de acesso à água potável mata 1,8 milhão de pessoa a cada ano. ',
				'Mais de 8 milhões de toneladas de plástico chegam ao mar todos os anos.',
				'Poluição mata duas vezes mais que o trânsito em São Paulo.',
				'Hoje o ar que você respira ganhará mais 100 bilhões de toneladas de CO2.',
				'A poluição dos ônibus de São Paulo mata 4.700 paulistanos por ano.',
				'Água contaminada mata mais de 3 milhões de pessoas por ano.',
        	],
        }

    }

    componentDidMount(){

    	setInterval(() => {

    		const date = new Date()

    		const year = date.getFullYear()
    		const month = ('0' + (date.getMonth() + 1)).slice(-2)
    		const day = date.getDate()
    		const hour = ('0' + date.getHours()).slice(-2)
    		const minutes = ('0' + date.getMinutes()).slice(-2)

    		this.setState({
    			timer: (
                    <span>
                        {day} <span className="day-divisor">/</span> {month} <span className="day-divisor">/</span> {year}
                        <span>•</span>
                        {hour}:{minutes}
                    </span>
                )
    		})


		}, 1000)
		
		const isMobile = window.innerWidth < 1024;

		if(!isMobile) {
			const timelineW = this.timelineImage.current.offsetWidth
			const timelineContainerW = this.timelineContainer.current.offsetWidth
			let scrollDifference = timelineW - timelineContainerW;
			let scrollSteps = scrollDifference / 5

			this.setState({
				timelineDifference: scrollDifference,
				timelineScroll: scrollSteps
			});
		}

        const marquee = new Marquee(document.querySelector('.phrases'), {
			rate: (isMobile ? -70 : -140),
            upDown: false,
        })

        loop(marquee, this.state.phrases.map(p => () => p), () => {

            const separator = document.createElement('div')
            separator.innerHTML = '&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'
            return separator

        })

    	setInterval(() => {

    		let phraseIndex = this.state.phraseIndex

    		phraseIndex++

    		if(phraseIndex >= this.state.phrases.length)
    			phraseIndex = 0

    		this.setState({
    			phraseIndex,
    		})


    	}, 7000)

	}
	
	onTimelinePrev = () => {
		if(this.state.timelinePosition > 0) {
			var marginPos = parseFloat(window.getComputedStyle(this.timelineImage.current).getPropertyValue('margin-left'));
			// console.log('antes ', marginPos)
			
			marginPos = marginPos + this.state.timelineScroll

			this.timelineImage.current.style['margin-left'] =  marginPos + 'px'

			// console.log(this.state.timelineScroll)
			// console.log('depois ',marginPos)

			this.setState({
				timelinePosition: (marginPos === 0 ? 0 : -marginPos)
			})
		}
	}
	
	onTimelineNext = () => {
		if(this.state.timelinePosition < this.state.timelineDifference) {
			var marginPos = parseFloat(window.getComputedStyle(this.timelineImage.current).getPropertyValue('margin-left'));
			
			marginPos = -(marginPos) + this.state.timelineScroll

			this.timelineImage.current.style['margin-left'] =  -marginPos + 'px'

			// console.log(this.state.timelineScroll)
			// console.log('depois ',marginPos)

			this.setState({
				timelinePosition: marginPos
			})
		}
    }

    onTwitterClick = (e) => {

        e.preventDefault()

        const message = `Quer saber o que acontece com o planeta? O Greenpeace mostrará os principais impactos ambientais através do seu logotipo. Uma visão rápida e confiável. Acesse.`

        window.open(`http://twitter.com/share?text=${message}&url=${window.location.href}`, 'facebookShare', 'height=500, width=500, top=' + (window.innerHeight / 2 - 250) + ', left=' + (window.innerWidth / 2 - 250) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')

    }

    onFacebookClick = (e) => {

        e.preventDefault()

        window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, 'facebookShare', 'height=500, width=500, top=' + (window.innerHeight / 2 - 250) + ', left=' + (window.innerWidth / 2 - 250) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')

    }

    onWhatsappClick = (e) => {

        e.preventDefault()

        const message = `Quer saber o que acontece com o planeta? O Greenpeace mostrará os principais impactos ambientais através do seu logotipo. Uma visão rápida e confiável. Acesse https://survivorlogo.greenpeace.org.br`

        window.open(`https://wa.me/?text=${message}`, 'whatsappShare', 'height=500, width=500, top=' + (window.innerHeight / 2 - 250) + ', left=' + (window.innerWidth / 2 - 250) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0')

    }

    render() {

    	const phrase = this.state.phraseIndex >= 0 ? this.state.phrases[this.state.phraseIndex] : ''

        const width = window.document.body.offsetWidth
		const isMobile = width <= 768
		
		const hideTimelinePrev = this.state.timelinePosition === 0 ? 'hide' : ''
		const hideTimelineNext = this.state.timelinePosition === this.state.timelineDifference ? 'hide' : ''

        return (
            <div className={'footer ' + this.props.className}>

                {/*<div className="years-of-fight">27 ANOS DE LUTA</div>*/}

                <div className="timeline">

                	<div className="founded">
                		FUNDAÇÃO<br/>
						DO GREENPEACE<br/>
						NO BRASIL.
					</div>

					<div className="timeline-bar">
						<div ref={this.timelineContainer} className="timeline-container">
							<img ref={this.timelineImage} src={require('../img/timeline.png')}/>
						</div>
						<button className={"timeline-nav timeline-nav-prev " + hideTimelinePrev} onClick={this.onTimelinePrev}>Anterior</button>
						<button className={"timeline-nav timeline-nav-next " + hideTimelineNext} onClick={this.onTimelineNext}>Próximo</button>
					</div>

					<div className="timeline-footer">

                        {isMobile ? <div className={"phrases mobile"}></div> : null}

						<div className="timer">{this.state.timer}</div>

						{!isMobile ? <div className={"phrases"}></div> : null}
						<div className="share">

							<button onClick={this.onTwitterClick} className="twitter"></button>
							<button onClick={this.onFacebookClick} className="facebook"></button>
							<button onClick={this.onWhatsappClick} className="whatsapp"></button>

						</div>

					</div>

                </div>

            </div>
        )

    }

}

export default Footer
